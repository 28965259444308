import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import Clock from '../../components/Clock/Clock'
import Logo from '../../assets/img/logo.png'
import moment from 'moment'
import Mint from '../../assets/img/mint.png'
import { PiBowlFoodLight } from "react-icons/pi";
import { FaMinus, FaPlus } from "react-icons/fa";


const Index = () => {

    const [menuList,
        setMenuList] = useState([])
    const [request,
        setRequest] = useState([])
    const [isRequest,
        setIsRequest] = useState(false)

    const getMenuList = () => {
        const date = moment().format('YYYY-MM-DD')
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/restaurant/60325/businessmenu/${date}`)
                .then(response => response.json())
                .then(data => {
                    setMenuList(data.data)
                })
        } catch (error) {
            return error
        }
    }

    useEffect(() => {
        getMenuList()
    }, [])

    const handleGetRequest = (item, type) => {
        const copy = [...request]
        if (type === 'add') {
            copy.push(item)
        } else {
            const index = copy.findIndex((element) => element.id == item.id);

            if (index !== -1) {
                copy.splice(index, 1);
            }
        }
        setRequest(copy)
    }

    const getUniqueArray = (array) => {
        return array.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
    }

    const handleSubmitRequest = () => {
        setIsRequest(false)
        setRequest([])
    }

    // function getPrice(rubleArray) {
    //     let totalKopecks = 0;
    //     console.log(rubleArray)
    //     rubleArray
    //     .map(el => el.price)
    //     .forEach(amount => {
    //         const [rubles, kopecks = '0'] = amount?.split('.') ?? [];
    //         totalKopecks += parseInt(rubles, 10) * 100 + parseInt(kopecks.padEnd(2, '0'), 10);
    //     });
    
    //     const totalRubles = Math.floor(totalKopecks / 100);
    //     const remainingKopecks = totalKopecks % 100;
    
    //     return `${totalRubles}.${remainingKopecks.toString().padStart(2, '0')}`;
    // }

    return (
        <div className={styles.wrapper}>
            <Clock/>
            <div className={styles.content}>
                <div className={styles.navbar}>
                    <img src={Logo} className={styles.logo} alt='Логотип'/>
                    <div className={styles.info}>
                        <span className={styles.infoTitle}>Бизнес-ланч</span>
                        <span className={styles.time}>12:00 - 15:00</span>
                    </div>
                </div>
                <div className={styles.menu}>
                    <div className={styles.menuWrapper}>
                        <div className={styles.navbar_btn} onClick={() => getMenuList()}>меню</div>

                        {request.length > 0
                            ? <div className={styles.requestButton} onClick={() => setIsRequest(true)}>заказать</div>
                            : <></>}

                        {isRequest
                            ? <div className={styles.request_popup}>
                                    <div>
                                        <div className={styles.requestTitle}>Спасибо!</div>
                                        <div className={styles.requestTitle}>Ващ заказ № 123</div>
                                    </div>

                                    <div className={styles.requestList}>
                                        {getUniqueArray(request).map((item, index) => {
                                            return (
                                                <div key={index} className={styles.requestItem}>
                                                    {item.title_ru}
                                                    - {request
                                                        .filter(item_ => item_.id === item.id)
                                                        .length}
                                                </div>
                                            )
                                        })
}
                                        <div className={styles.price}>- 350 руб.</div>
                                    </div>
                                    <div className={styles.endText}>
                                        <div className={styles.cancel} onClick={() => setIsRequest(false)}>отменить заказ</div>
                                        <div className={styles.off}>К Вам подойдет официант</div>
                                        <div className={styles.ok} onClick={() => handleSubmitRequest()}>
                                            <img src={Mint} className={styles.mint} alt='Mint'/>
                                            ОК
                                        </div>
                                    </div>
                                </div>
                            : <></>}

                        {menuList[0]
                            ?.categories
                                .map((item) => {
                                    return (
                                        <div className={styles.menu_row}>
                                            <div className={styles.menu_row_wrapper}>
                                                <div className={styles.titleRow}>
                                                    <p className={styles.menu_title__Mid}>{item.category}</p>
                                                </div>
                                                {item
                                                    .menu
                                                    .map((item_) => {
                                                        return (
                                                            <div
                                                                key={item_.id}
                                                                className={styles.menuBlock}
                                                                style={{
                                                                backgroundImage: `url(${item_.image
                                                                    ?.[0]
                                                                        ?.Url})`
                                                            }}>
                                                                {item_.image
                                                                    ?.length === 0 ? <i className={styles.foodicon}><PiBowlFoodLight/></i> : ''}   
                                                                <div
                                                                    style={{
                                                                    display: request
                                                                        .filter(item => item.id === item_.id)
                                                                        .length > 0
                                                                        ? 'flex'
                                                                        : '',
                                                                    boxShadow: request
                                                                        .filter(item => item.id === item_.id)
                                                                        .length > 0
                                                                        ? ' 0 0 0 10px rgb(191, 243, 206)'
                                                                        : ''
                                                                }}
                                                                    className={styles.menu_popup}>
                                                                    <span
                                                                        onClick={() => handleGetRequest(item_, 'remove')}
                                                                        className={styles.count_minus}><FaMinus/></span>
                                                                    <span className={styles.count}>{request
                                                                            .filter(item => item.id === item_.id)
                                                                            .length}</span>
                                                                    <span
                                                                        onClick={() => handleGetRequest(item_, 'add')}
                                                                        className={styles.count_plus}><FaPlus/></span>
                                                                </div>
                                                                <div className={styles.menu_text}>
                                                                    <p className={styles.menu_title}>{item_.title_ru}</p>
                                                                    <p className={styles.menu_description}>{item_.description_ru}</p>
                                                                </div>
                                                            </div>
                                                        )

                                                    })
}
                                            </div>
                                        </div>
                                    )
                                })
}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index